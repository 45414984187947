import React, { useState, useEffect } from "react";
import Loader from "../Loader";

import "./styles.css";

const title = "Automatically Generate Report";
const description =
    "Automatically generate reports from a .csv, .xslx, .xlsm, and .xlsb. Identify correlations, quantile, descriptive statistics, and visualize with graphs. The first row of each column will serve as the variable name.";

const caption = "generated by Pandas Profiling";

const maxFileSizeMb = 2;

const Introduction = () => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const [file, setFile] = useState<File | null>(null);
    const [reportName, setReportName] = useState<string>("");
    const [fileName, setFileName] = useState<null | string>(null);
    const [reportPath, setReportPath] = useState<null | string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    /**
     * Error occur when the file size > 100mb and not supported extension.
     */
    const [error, setError] = useState<string>("");

    function uploadHandler(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (!reportName) {
            setError("Report Name is required");
            return;
        }
        if (file) {
            const fl = new FileReader();
            fl.addEventListener("loadend", ({ target }) => {
                if (target) {
                    if (!target.result) return;
                    const data = {
                        data: target.result as string,
                        report_name: reportName,
                    };
                    setIsLoading(true);
                    fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/reports`,
                        {
                            method: "POST",
                            mode: "cors",
                            headers: {
                                "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                            },
                            body: JSON.stringify(data),
                        }
                    )
                        .then((res) => res.json())
                        .then((dt) => {
                            setReportPath(
                                `${process.env.REACT_APP_BACKEND_URL}/reports/${dt}`
                            );
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            console.log(err);
                            setError("Something went wrong try again...");
                        });
                }
            });
            fl.readAsText(file, "UTF-8");
        }
    }

    function onSelectFileHandler(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        fileInputRef.current?.click();
    }

    useEffect(() => {
        if (!fileInputRef.current) return;
        fileInputRef.current.addEventListener("change", function () {
            const file = this.files?.[0];
            if (!file) return;
            const mb = file.size / 1_000_000;
            if (mb >= maxFileSizeMb) {
                setError(
                    `File size of ${mb.toFixed(
                        2
                    )}mb is too large. Must be smaller than ${maxFileSizeMb}mb.`
                );
                setFile(null);
                setFileName(null);
                setReportName("");
                return;
            } else setError("");
            if (file) {
                setFile(file);
                setFileName(file.name);
            }
        });
    }, [fileInputRef]);

    return (
        <div className="intro">
            <h1 className="intro__title">{title}</h1>
            <p className="intro__description mc">{description}</p>
            <p className="intro__caption">{caption}</p>
            <input
                type="file"
                ref={fileInputRef}
                className="intro__input_file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            {!file && !reportPath && (
                <button className="intro__button" onClick={onSelectFileHandler}>
                    Select File
                </button>
            )}
            {file && !reportPath && !isLoading && (
                <>
                    <div>
                        <p className="intro__caption--greyed">{fileName}</p>
                    </div>
                    <div>
                        <input
                            className="intro__input"
                            value={reportName}
                            onChange={(e) => setReportName(e.target.value)}
                            placeholder="Report Title required..."
                        />
                    </div>
                    <button
                        className="intro__button  mr1"
                        onClick={onSelectFileHandler}
                    >
                        Select Other File
                    </button>
                    <button
                        className={`intro__button${
                            reportName ? "" : " intro__button--disabled"
                        }`}
                        onClick={uploadHandler}
                        disabled={!Boolean(reportName)}
                    >
                        Generate Report
                    </button>
                </>
            )}
            {reportPath && (
                <>
                    <div className="notification">
                        <div>
                            <p className="notification__success">
                                Successfully generated report!
                            </p>
                        </div>
                        <div>
                            <p>
                                view report{" "}
                                <a
                                    className="notification__link"
                                    href={reportPath}
                                >
                                    here
                                </a>
                            </p>
                        </div>
                    </div>
                </>
            )}
            {isLoading && (
                <div className="loader__container">
                    <div className="loader__caption">Generating report...</div>
                    <Loader />
                </div>
            )}
            {error && (
                <div className="error">
                    <p>{error}</p>
                </div>
            )}
        </div>
    );
};

export default Introduction;
