import React from "react";
import "./styles.css";

type Props = {
    variant?: "small" | "large";
};

function Loader({ variant = "small" }: Props) {
    return (
        <div
            className={`loader${variant === "large" ? " loader--large" : ""}`}
        />
    );
}

export default Loader;
