import React from "react";
import "./styles.css";

type Props = {
    text: string;
    href: string;
};

function Link({ text, href }: Props) {
    return (
        <div className="link">
            <a
                className="link__a"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        </div>
    );
}

export default Link;
