import React from "react";
import "./styles.css";
import VarDescImg from "../../assets/images/pandas-prof-report-desc.png";
import CorrImg from "../../assets/images/pandas-prof-report-corr.png";
import SummaryImg from "../../assets/images/pandas-prof-report-summary.png";
import SampleImg from "../../assets/images/pandas-prof-report-sample.png";
import Section from "./Section";

const data = [
    {
        imageSrc: SampleImg,
        caption: "View the first and last ten samples of the dataset.",
    },
    {
        imageSrc: SummaryImg,
        caption:
            "Get an overview of the whole dataset. Generated metadata, variable types, and other important information about the dataset.",
    },
    {
        imageSrc: CorrImg,
        caption: "Explore interactions and relationships between variables.",
    },
    {
        imageSrc: VarDescImg,
        caption:
            "Explore each variable with the generated statistics and graph.",
    },
];

function Features() {
    return (
        <div className="features">
            <div>
                <h2 className="features__title">Features</h2>
            </div>
            <div>
                {data.map(({ imageSrc, caption }, idx) => (
                    <Section
                        imageSrc={imageSrc}
                        caption={caption}
                        key={idx}
                        // count={idx + 1}
                    />
                ))}
            </div>
        </div>
    );
}

export default Features;
