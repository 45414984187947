import React from "react";
import "./styles.css";

const name = "Jamsed Cabili";
// const about =
//     "It should be free and easy to be able to generate reports from spreadsheet.";
// const about = "I've been using Pandas Profiling.";

const contacts = [
    {
        label: "Twitter",
        url: "https://twitter.com/jamsedcabili",
    },
    {
        label: "GitHub",
        url: "https://github.com/Panda0123",
    },
];

const builtWith = [
    {
        label: "React",
        url: "https://reactjs.org/",
    },
    {
        label: "FastAPI",
        url: "https://fastapi.tiangolo.com/",
    },
    {
        label: "Pandas Profiling",
        url: "https://pandas-profiling.github.io/pandas-profiling/docs/master/rtd/",
    },
];

function Footer() {
    return (
        <footer className="footer">
            <div>
                <div className="footer__item">
                    <h3 className="footer__label">Created by</h3>
                    <p className="footer__value">{name}</p>
                </div>
                {/* <div className="footer__item">
                    <h3 className="footer__label">About</h3>
                    <p className="footer__value">{about}</p>
                </div> */}
            </div>
            <div>
                <div className="footer__item">
                    <h3 className="footer__label">Built With</h3>
                    {builtWith.map(({ label, url }, idx) => (
                        <div key={idx}>
                            <a className="footer__value value--link" href={url}>
                                {label}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <div className="footer__item">
                    <h3 className="footer__label">Contact</h3>
                    {contacts.map(({ label, url }, idx) => (
                        <div key={idx}>
                            <a className="footer__value value--link" href={url}>
                                {label}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
