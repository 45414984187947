import React from "react";
import "./styles.css";

type Props = {
    children: React.ReactNode;
};

function Layout({ children }: Props) {
    return (
        <div className="App">
            <main className="layout">{children}</main>
        </div>
    );
}

export default Layout;
