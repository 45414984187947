import React from "react";
import Divider from "../Divider";
import Features from "../Features";
import Footer from "../Footer";
import Introduction from "../Introduction";
import Layout from "../Layout";
import Link from "../Link";

function HomePage() {
    return (
        <Layout>
            <Introduction />
            <Divider />
            <Features />
            <Link text="View Sample" href="/TitanicReport.html" />
            <Divider />
            <Footer />
        </Layout>
    );
}

export default HomePage;
