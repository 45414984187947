import React from "react";
import "./styles.css";

type Props = {
    imageSrc: string;
    caption: string;
    count?: number;
};

function Section({ imageSrc, caption, count }: Props) {
    return (
        <section className="features__section">
            {count && <p className="features__section__number">{count}.</p>}
            <div className="features__section__caption">
                <p>{caption}</p>
            </div>
            <div>
                <img
                    className="features__section__image"
                    src={imageSrc}
                    alt=""
                />
            </div>
        </section>
    );
}

export default Section;
